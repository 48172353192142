<template>
  <div v-if="step == 1">
    <div class="card shadow-sm">
      <form id="formItems" class="needs-validation" novalidate>
        <div class="card-header">
          <h3 class="card-title" style="font-size: 20px; font-weight: bold">
            เอกสารรับเข้า
          </h3>
        </div>
        <!-- form -->
        <div class="card-body">
          <div class="row mb-6">
            <div class="wrapper-progressBar">
              <ul class="progressBar">
                <li class="active">สร้างเอกสาร</li>
                <li :class="code >= 1 ? 'active' : ''">
                  ผู้เกี่ยวข้อง 1 ลงนาม
                </li>
                <li :class="code >= 2 ? 'active' : ''">
                  ผู้เกี่ยวข้อง 2 ลงนาม
                </li>
                <li :class="code >= 3 ? 'active' : ''">เสร็จสิ้น</li>
              </ul>
            </div>
          </div>

          <!-- -->
          <div class="row d-flex justify-content-center mb-4">
            <label for="secondaryProductCode" class="form-label col-sm-10"
              >จาก</label
            >
            <div class="col-sm-10">
              <!-- <input
                v-model="form.from"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
              /> -->
              <span>สารบรรณกลาง</span>
              <div class="invalid-feedback text-end">กรุณากรอก</div>
            </div>
          </div>
          <div class="row d-flex justify-content-center mb-4">
            <label for="secondaryProductName" class="form-label col-sm-10"
              >เรียน</label
            >
            <div class="col-sm-10">
              <!-- <input
                v-model="form.to"
                type="text"
                class="form-control"
                name="secondaryProductName"
                id="secondaryProductName"
              /> -->
              <span>หัวหน้าฝ่าย</span>
              <div class="invalid-feedback text-end">กรุณากรอก</div>
            </div>
          </div>
          <div class="row d-flex justify-content-center mb-4">
            <label
              for="secondaryProductName"
              class="form-label col-sm-10 text-center"
              >เรื่อง <span>การจัดทำโครงการ</span></label
            >
            <div class="col-sm-10">
              <!-- <input
                v-model="form.subject"
                type="text"
                class="form-control"
                name="secondaryProductName"
                id="secondaryProductName"
              /> -->

              <div class="invalid-feedback text-end">กรุณากรอก</div>
            </div>
          </div>

          <div class="row d-flex justify-content-center mb-4">
            <label
              for="secondaryProductName"
              class="form-label col-sm-10 text-end"
              >วันที่ <span>29 พฤษจิกายน 2565</span></label
            >
            <div class="col-sm-10">
              <!-- <input
                v-model="form.date"
                type="text"
                class="form-control"
                name="secondaryProductName"
                id="secondaryProductName"
              /> -->

              <div class="invalid-feedback text-end">กรุณากรอก</div>
            </div>
          </div>

          <div class="row d-flex justify-content-center mb-4">
            <label
              for="secondaryProductName"
              class="form-label col-sm-10 text-end"
              >วันที่รับหนังสือ <span>29 พฤษจิกายน 2565</span></label
            >
            <div class="col-sm-10">
              <!-- <input
                v-model="form.since"
                type="text"
                class="form-control"
                name="secondaryProductName"
                id="secondaryProductName"
              /> -->

              <div class="invalid-feedback text-end">กรุณากรอก</div>
            </div>
          </div>
        </div>

        <hr class="pt-0 mt-0" style="color: LightGrey" />
        <form id="formItems" class="needs-validation" novalidate>
          <!-- form -->
          <div class="card-body row d-flex justify-content-center">
            <!-- -->

            <!-- <div class="col-sm-10 mb-4">
              <label for="validationTooltip01" class="form-label"
                >ประเภทไฟล์</label
              >
              <select
                :style="
                  isSubmit
                    ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                    : ''
                "
                class="form-select"
                v-model="form.status"
              >
                <option value="normal">file type1</option>
                <option value="holding">file type2</option>
                <option value="normal">file type3</option>
              </select>
            </div>

            <div class="col-sm-8">
              <label for="secondaryProductCode" class="form-label col-sm-10"
                >นำเอกสารเข้าระบบ</label
              >
              <div>
                <input
                  type="file"
                  class="form-control"
                  name="secondaryProductCode"
                  id="secondaryProductCode"
                />
                <div class="invalid-feedback text-end">กรุณากรอก</div>
              </div>
            </div>
            <div class="col-sm-2">
              <label
                for="secondaryProductCode"
                class="form-label col-sm-10"
              ></label>
              <button
                type="submit"
                class="btn btn-sm btn-light btn-primary ms-3 me-2 mt-2"
                style="font-size: 14px; font-weight: bold; place-self: center"
                @click="submit"
                :disabled="loadingUpdate"
              >
                <div
                  v-if="loadingUpdate"
                  class="spinner-grow spinner-grow-sm text-light"
                  role="status"
                ></div>
                บันทึกไฟล์
              </button>
            </div> -->

            <!-- <footer class="m-6">
            <div class="card-toolbar row justify-content-center">
              <div class="col-sm-10">
                <button
                  type="submit"
                  class="btn btn-sm btn-light btn-primary ms-3 me-2"
                  style="font-size: 14px; font-weight: bold"
                  @click="submit"
                  :disabled="loadingUpdate"
                >
                  <div
                    v-if="loadingUpdate"
                    class="spinner-grow spinner-grow-sm text-light"
                    role="status"
                  ></div>
                  บันทึกไฟล์
                </button>
              </div>
            </div>
          </footer> -->

            <div class="table-responsive col-sm-10 mt-6">
              <table
                class="
                  table table-hover table-rounded table-striped
                  border
                  gy-7
                  gs-7
                "
              >
                <thead>
                  <tr
                    class="
                      fw-bold
                      fs-5
                      text-gray-800
                      border-bottom-2 border-gray-200
                    "
                  >
                    <th></th>
                    <th>ลำดับที่</th>
                    <th>ชื่อไฟล์</th>
                    <th>ประเภทไฟล์</th>
                    <th>ไฟล์แนบ</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-if="dataItemsShow.length < 1">
                    <td class="text-center" colspan="10">ไม่มีข้อมูล</td>
                  </tr>
                  <tr v-for="(item, index) in dataItemsShow" :key="index">
                    <td></td>
                    <td>{{ !showing1 ? index + 1 : showing1 + index }}</td>
                    <td>{{ item.filename }}</td>
                    <td>{{ item.filetype }}</td>

                    <!-- <td
                @click="item.status !== '1' ? handleApprove(item) : ''"
                :style="
                  item.status === '1'
                    ? 'cursor: ; color: green'
                    : item.status === '0'
                    ? 'cursor: pointer; color: blue'
                    : 'cursor: pointer; color: red'
                "
              >
                {{
                  item.status === "1"
                    ? "อนุมัติ"
                    : item.status === "0"
                    ? "รออนุมัติการเพิ่ม"
                    : "ไม่อนุมัติ"
                }}
              </td> -->
                    <td>
                      <div class="row">
                        <div class="col-sm-6 d-flex justify-content-md-end">
                          <button
                            type="button"
                            class="
                              btn
                              btn-link
                              btn-color-muted
                              btn-active-color-success
                              p-0
                            "
                          >
                            <!-- @click="goToEdit(item.id)" -->
                            <i class="bi bi-file-earmark-check"></i>
                          </button>
                        </div>
                        <!-- <div class="col-sm-6 d-flex">
                          <button
                            type="button"
                            class="
                              btn
                              btn-link
                              btn-color-muted
                              btn-active-color-danger
                              p-0
                            "
                            @click="goToDelete(item, index)"
                          >
                            <i class="bi bi-trash3-fill"></i>
                          </button>
                        </div> -->
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr class="pt-0 mt-0" style="color: LightGrey" />

          <div class="card-body row d-flex justify-content-center">
            <label for="secondaryProductCode" class="form-label col-sm-10"
              >สรุปรายละเอียด</label
            >
            <div class="col-sm-10">
              <!-- <input
                v-model="form.summarize"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
              /> -->
              <!-- <editor
                initialValue="<p>Initial editor content</p>"
                :init="{
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap',
                    'searchreplace visualblocks code fullscreen',
                    'print preview anchor insertdatetime media',
                    'paste code help wordcount table',
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic |         alignleft aligncenter alignright |         bullist numlist outdent indent | help',
                }"
              >
              </editor> -->
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Why do we use it? It is a long established fact that
              a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it
              has a more-or-less normal distribution of letters, as opposed to
              using 'Content here, content here', making it look like readable
              English. Many desktop publishing packages and web page editors now
              use Lorem Ipsum as their default model text, and a search for
              'lorem ipsum' will uncover many web sites still in their infancy.
              Various versions have evolved over the years, sometimes by
              accident, sometimes on purpose (injected humour and the like).
              Where does it come from? Contrary to popular belief, Lorem Ipsum
              is not simply random text. It has roots in a piece of classical
              Latin literature from 45 BC, making it over 2000 years old.
              Richard McClintock, a Latin professor at Hampden-Sydney College in
              Virginia, looked up one of the more obscure Latin words,
              consectetur, from a Lorem Ipsum passage, and going through the
              cites of the word in classical literature, discovered the
              undoubtable source. Lorem Ipsum comes from sections 1.10.32 and
              1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good
              and Evil) by Cicero, written in 45 BC. This book is a treatise on
              the theory of ethics, very popular during the Renaissance. The
              first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes
              from a line in section 1.10.32. The standard chunk of Lorem Ipsum
              used since the 1500s is reproduced below for those interested.
              Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum"
              by Cicero are also reproduced in their exact original form,
              accompanied by English versions from the 1914 translation by H.
              Rackham.
            </div>
            <!-- -->
           
            <hr class="pt-0 mt-10 ,b-2" style="color: LightGrey" />
           
            <div class="row d-flex justify-content-center mb-4 col-sm-10">
              <label for="validationTooltip01" class="form-label"
                >ส่งเอกสาร</label
              >
              <select
                :style="
                  isSubmit
                    ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                    : ''
                "
                class="form-select col-sm-10"
                v-model="form.status"
              >
                <option value="normal">หน่วยงาน1</option>
                <option value="holding">หน่วยงาน2</option>
                <option value="normal">หน่วยงาน3</option>
              </select>
            </div>
          </div>

          <footer class="m-6">
            <div class="card-toolbar row justify-content-center">
              <div class="col-sm-10">
                <button
                  type="submit"
                  class="btn btn-sm btn-light btn-primary ms-3 me-2"
                  style="font-size: 14px; font-weight: bold"
                  @click="submit"
                  :disabled="loadingUpdate"
                >
                  <div
                    v-if="loadingUpdate"
                    class="spinner-grow spinner-grow-sm text-light"
                    role="status"
                  ></div>
                  แก้ไข
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-light"
                  style="font-size: 14px; font-weight: bold"
                  @click="cancel"
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </footer>
        </form>

        <!-- <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="botton"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="step=2"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              ถัดไป
            </button>
            
          </div>
        </div>
      </footer> -->
      </form>
    </div>
  </div>

  <div v-if="step == 2">
    <div class="card shadow-sm">
      <form id="formItems" class="needs-validation" novalidate>
        <div class="card-header">
          <h3 class="card-title" style="font-size: 20px; font-weight: bold">
            เอกสารรับเข้า step2
          </h3>
        </div>
        <!-- form -->
        <div class="card-body">
          <!-- -->
          <div class="row d-flex justify-content-center mb-4">
            <label for="secondaryProductCode" class="form-label col-sm-10"
              >สรุปรายละเอียด</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.summarize"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
              />
            </div>
          </div>
        </div>

        <hr class="pt-0 mt-0" style="color: LightGrey" />

        <footer class="m-6">
          <div class="card-toolbar row justify-content-center">
            <div class="col-sm-10">
              <button
                type="botton"
                class="btn btn-sm btn-light btn-primary ms-3 me-2"
                style="font-size: 14px; font-weight: bold"
                @click="step = 3"
                :disabled="loading"
              >
                <div
                  v-if="loading"
                  class="spinner-grow spinner-grow-sm text-light"
                  role="status"
                ></div>
                ถัดไป
              </button>
            </div>
          </div>
        </footer>
      </form>
    </div>
  </div>

  <div v-if="step == 3">
    <div class="card shadow-sm">
      <form id="formItems" class="needs-validation" novalidate>
        <div class="card-header">
          <h3 class="card-title" style="font-size: 20px; font-weight: bold">
            เอกสารรับเข้า step3
          </h3>
        </div>
        <!-- form -->
        <div class="card-body row d-flex justify-content-center">
          <!-- -->
          <div class="row d-flex justify-content-center mb-4 col-sm-10">
            <label
              for="validationTooltip01"
              class="form-label justify-content-center"
              >ส่งเอกสาร</label
            >
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select col-sm-10"
              v-model="form.status"
            >
              <option value="normal">หน่วยงาน1</option>
              <option value="holding">หน่วยงาน2</option>
              <option value="normal">หน่วยงาน3</option>
            </select>
          </div>
        </div>

        <hr class="pt-0 mt-0" style="color: LightGrey" />

        <footer class="m-6">
          <div class="card-toolbar row justify-content-center">
            <div class="col-sm-10">
              <button
                type="botton"
                class="btn btn-sm btn-light btn-primary ms-3 me-2"
                style="font-size: 14px; font-weight: bold"
                @click="step = 4"
                :disabled="loading"
              >
                <div
                  v-if="loading"
                  class="spinner-grow spinner-grow-sm text-light"
                  role="status"
                ></div>
                save
              </button>
            </div>
          </div>
        </footer>
      </form>
    </div>
  </div>

  <div v-if="step == 4">step5</div>

  <div v-if="step == 6">step6</div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";
import Editor from "@tinymce/tinymce-vue";

export default {
  setup() {
    document.title = "bem-document-demo เพิ่มประเภทสินค้า";
  },
  components: {
    editor: Editor,
  },
  data: () => ({
    loading: false,
    isSubmit: false,
    step: 1,

    form: {
      sub_product_group_code: "",
      sub_product_group_name: "",
      selectMainProduct: "",
      status: "1",
    },

    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,

    mainProduct: [],
    dataItemsShow: [],
    tableItems: [],
    code: 1,
  }),

  created() {
    // this.getMainProduct();
    this.code = this.$route.query.code;
    this.dataItemsShow = [
      {
        filename: "สารบรรณกลาง",
        filetype: "pdf",
      },
      {
        filename: "สารบรรณกลาง",
        filetype: "pdf",
      },
      {
        filename: "สารบรรณกลาง",
        filetype: "pdf",
      },
      {
        filename: "สารบรรณกลาง",
        filetype: "pdf",
      },
    ];
    this.tableItems = this.dataItemsShow;
    this.allData = this.dataItemsShow;
  },

  watch: {
    "form.productGroupId"(val) {
      console.log({ val });
    },
  },

  methods: {
    async getMainProduct() {
      let responseData = [];
      try {
        responseData = await whApi.mainProduct.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.mainProduct = responseData.data;
        this.form.productGroupId = responseData.data[0].id;
      }
    },
    async submit() {
      let createResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      if (this.isValid) {
        this.loading = true;

        this.form = {
          ...this.form,
          mainProductGroupId: this.form.productGroupId,
          companyId: parseInt(localStorage.getItem("companyId")),
        };

        createResponse = await whApi.subProduct.create(this.form);
        if (createResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: "เพิ่มสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.push({
              path: "/warehouse/WH2SUB",
              query: {},
            });
          });
          this.loading = false;
        } else {
          Swal.fire({
            icon: "error",
            title: `${createResponse.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
    },

    checkFormIsEmpty() {
      // !this.form.code
      //   ? (this.isValid = false)
      //   :
      !this.form.from
        ? (this.isValid = false)
        : !this.form.to
        ? (this.isValid = false)
        : !this.form.subject
        ? (this.isValid = false)
        : !this.form.date
        ? (this.isValid = false)
        : !this.form.since
        ? (this.isValid = false)
        : (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/saraban5",
        query: {},
      });
    },
  },
};
</script>
<style>
.wrapper-progressBar {
  width: 100%;
}

.progressBar {
}

.progressBar li {
  list-style-type: none;
  float: left;
  width: 25%;
  position: relative;
  text-align: center;
}
.progressBar li:before {
  content: " ";
  line-height: 30px;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  border: 1px solid #ddd;
  border-left: none;
  display: block;
  text-align: center;
  margin: 8.5px auto 0px;
  background-color: #eee;
}
.progressBar li:after {
  content: "";
  position: absolute;
  width: 97%;
  height: 5px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-right: none;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.progressBar li:first-child:after {
  content: none;
}

.progressBar li.active {
  color: dodgerblue;
}

.progressBar li.active:before {
  border-color: dodgerblue;
  background-color: dodgerblue;
}

.progressBar .active:after {
  background-color: dodgerblue;
}
</style>